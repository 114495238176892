import React from 'react';

export default function ContactUsSection() {
    return <section id="contact" className="contact-area">
        <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10">
                <div className="section-title text-center pb-30">
                    <h3 className="title">Coming Soon</h3>
                    <p className="text">More Features coming soon!</p>
                </div>
            </div>
        </div>
    </section>
}
