import React from "react";

export default function ControlledOpenSelect(Props) {

    var TwoDArray = Props.TwodArray;
    var Name = Props.Name;
    const itemsList = [];


    /*
            var itemsList = TwoDArray.map(function(key, index) 
            {
                return <li><span className="rp-kits-list-modal">{key}</span>: {TwoDArray[key].join(', ')}</li>
            });
            */
        
for (var key in TwoDArray) {
    if (TwoDArray.hasOwnProperty(key)) {
        itemsList.push((<li><span className="rp-kits-list-modal">{key}</span>: {TwoDArray[key].join(', ')}</li>))
        //console.log(key + " -> " + TwoDArray[key]);
    }
}
        return (            
            <div className="modal modal.off modal-item-info modal-fit" id={"modal-itemlist-" + Name}>
                    <h2>{Name} Roleplay Server Kits</h2>
                    <ul>{itemsList.map(x => (x))}</ul>
            </div>
        )
        
    
}
