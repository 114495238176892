
import React from 'react';
import Helmet from "react-helmet";

export default function AppHead() {
    return <Helmet>
      <meta charset="utf-8" />
      <link rel="icon" href="favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content="Pandahut Network Website" />
      <link rel="manifest" href="manifest.json" />
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"/>      
      <title>Pandahut Network</title>
  </Helmet>
}