import React from 'react';
//import universal from 'react-universal-component'

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import {  useEffect, useRef } from "react";
//const ModalPaymentForm = universal(() => import('./modal-payment-window'));
const ModalPaymentForm = React.lazy(() =>
  import("./modal-payment-window")
)

export default function FormDialog(props) {

  var rank = props.rank;
  var size = props.size;
  var rankName = "Unknown-Rank";
  if (rank != null && rank != undefined && rank.RankName != undefined) {
    rankName = rank.RankName;
  }
  const dialogRef = useRef(null)
  // const executeScroll = () => dialogRef.current.scrollIntoView()    


  const [open, setOpen] = React.useState(false);

  var handleClickOpen = () => {
    setOpen(true);
    console.log("Opening payment modal for:")
    console.log(props.rank.RankName);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    try {
    if (localStorage.getItem("selectedRank")) {
      if (localStorage.getItem("selectedRank") === rank.RankName) {
        if (open === false)
        {
        console.log("Trying to open rank");
        setOpen(true);
        localStorage.removeItem("selectedRank");
        //executeScroll();
        }
      }
    }
  }
  catch (Exception) {
    console.log("User probably disabled localStorage");
    console.error(Exception);
  }
  }, [rank, open]);


 const buyNowSignUp = 'Buy Now';
  
  /* if (rankName == 'VIP')
   handleClickOpen = () => {
    setOpen(false);
  }; */
  
  return (
    <div className={`modal-btn-wrapper ${rankName}`}>
  <div className={`column-item column-item-footer ${rankName}`} onClick={handleClickOpen} >
    <span className={`column-footer-buy-btn ${rankName}`} >{buyNowSignUp}</span>
    </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" ref={dialogRef}>
        {open ? (
        <DialogContent>
          <React.Suspense>
            <ModalPaymentForm Rank={rank} size={size}/>
            </React.Suspense>
        </DialogContent>
        ): null}
      </Dialog>
    </div>
  );
}


