import React from 'react';
import { useRef } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ModalStoreItemInfo from './modal-iteminfo-window';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';


export default function FormDialog(props) {
  var Name = props.Name;
  var TwodArray = props.TwodArray;
  const dialogRef = useRef(null)
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // useEffect(() => {
  //   if (localStorage.getItem("selectedRank")) {
  //     if (localStorage.getItem("selectedRank") === rank.rank) {
  //       if (open === false)
  //       {
  //       console.log("Trying to open rank");
  //       setOpen(true);
  //       localStorage.removeItem("selectedRank");
  //       //executeScroll();
  //       }
  //     }
  //   }
  // });


  return (
  <div className='modal-btn-wrapper'>
  <div className='column-item info-modal-btn' onClick={handleClickOpen} >
    <span className='column-item-info-modal-btn' >Click to View </span> <FontAwesomeIcon className="fa-flip-horizontal sm-font" icon={faExternalLinkAlt} />
    </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" ref={dialogRef}>
        <DialogContent>
            <ModalStoreItemInfo Name={Name} TwodArray={TwodArray}/> 
        </DialogContent> 
      </Dialog> 
    </div>
  );
}


