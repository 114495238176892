import React from 'react';
import { useRef } from "react";
import ReactTooltip from 'react-tooltip';
import ModalDialog from './modal-payment-button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default function MvpppSubs(servtype = 'PvP') {
    var ref = useRef(null);

    if (servtype && servtype.refCarousel)
        ref = servtype.refCarousel;

    const onPrevClick = () => {
        ref.current.prev();
    };

    const [checkboxes, setCheckboxes] = React.useState({
        checkedAssault: false,
        checkedThief: false,
        checkedMedic: false,
        checkedRunner: false,
        checkedFlanker: false,
        checkedHooligan: false,
        checkedLightSniper: false,
        checkedForest: false,
        checkedSneaky: false,
        checkedStalker: false,
        checkedBoom: false,
        checkedHeavySniper: false,
        checkedSharpShooter: false,
        checkedSnooper: false
    });

    const [MVPPRankObj, SetMVPPOBject] = React.useState({ RankName: "MVP++ - 30 Days", Price: "$8.99 USD", UpgradeOptions: [{ "Name": "MVP++ - 90 Days", "PriorRank": "None", "Price": 25.00 }, { "Name": "MVP++ - 180 Days", "PriorRank": "None", "Price": 50.00 }], PermWeapons: 0, SelectedKits: []});


    const GetSelectedKits = (newcheckboxes) => {
        var arr = [
          ];
          if (newcheckboxes.checkedAssault)
          {
              arr.push("Assault");
          }
          if (newcheckboxes.checkedThief)
          {
              arr.push("Thief");
          }
          if (newcheckboxes.checkedMedic)
          {
              arr.push("Medic");
          }
          if (newcheckboxes.checkedRunner)
          {
              arr.push("Runner");
          }
          if (newcheckboxes.checkedFlanker)
          {
              arr.push("Flanker");
          }
          if (newcheckboxes.checkedHooligan)
          {
              arr.push("Hooligan");
          }
          if (newcheckboxes.checkedLightSniper)
          {
              arr.push("LightSniper");
          }
          if (newcheckboxes.checkedForest)
          {
              arr.push("Forest");
          }
          if (newcheckboxes.checkedSneaky)
          {
              arr.push("Sneaky");
          }
          if (newcheckboxes.checkedStalker)
          {
              arr.push("Stalker");
          }
          if (newcheckboxes.checkedBoom)
          {
              arr.push("Boom");
          }
          if (newcheckboxes.checkedHeavySniper)
          {
              arr.push("Heavysniper");
          }
          if (newcheckboxes.checkedSharpShooter)
          {
              arr.push("Sharpshooter");
          }
          if (newcheckboxes.checkedSnooper)
          {
              arr.push("Snooper");
          }
          SetMVPPOBject({...MVPPRankObj,  SelectedKits: arr})
    }

    const [cards, setCards] = React.useState({
        classList: 'cards'
    });

    const [card, setCard] = React.useState(Array(14).fill({
        classList: 'card',
        zIndex: 9
    }));

    const handleChange = (event) => {
        const checkboxesArr = Object.values(checkboxes);
        var countChecked = checkboxesArr.filter(Boolean).length;

        //KitsAmount default = 4 kits

        if (countChecked < 4 && event.target.checked) {
            countChecked++;
            event.target.parentElement.parentElement.className = 'MuiButtonBase-root MuiIconButton-root PrivateSwitchBase-root-1 MuiCheckbox-root MuiCheckbox-colorSecondary PrivateSwitchBase-checked-2 Mui-checked MuiIconButton-colorSecondary';
            setCheckboxes({ ...checkboxes, [event.target.name]: true });
            GetSelectedKits({ ...checkboxes, [event.target.name]: true });
        }

        else if (!event.target.checked) {
            countChecked--;
            event.target.parentElement.parentElement.className = 'MuiButtonBase-root MuiIconButton-root PrivateSwitchBase-root-1 MuiCheckbox-root MuiCheckbox-colorSecondary MuiIconButton-colorSecondary';
            setCheckboxes({ ...checkboxes, [event.target.name]: false });
            GetSelectedKits({ ...checkboxes, [event.target.name]: false });
        }
        else return;
    }

    const openCard = (e, index) => {
        e = e || window.event;
        var isShowing = false;

        e.preventDefault();


        if (card && card[index] && card[index].classList && card[index].classList.includes('show')) {
            isShowing = true
        }

        if (cards && cards.classList && cards.classList.includes('showing')) {
            setCards({ classList: 'cards' });
            setCard({ ...card, [index]: { classList: 'card' } });
            let classArr = new Array(14).fill({ classList: 'card' });
            setCard(classArr)

            if (isShowing) {
                let classArr = new Array(14).fill({ classList: 'card' });
                setCard(classArr)
                setCards({ classList: 'cards' });;
            }
            else if (card) {
                let classArr = new Array(14).fill({ classList: 'card' });
                setCards({ classList: 'cards showing' });
                setCard({ ...classArr, [index]: { classList: 'card show' } });
            }
        }

        else if (card) {
            setCards({ ...cards, classList: 'cards showing' });
            let classArr = new Array(14).fill({ classList: 'card' });
            setCard(classArr)
            setCard({ ...card, [index]: { classList: 'card show' } });
        }
    }


    return <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <div className="slider-content"> <div id="slideset2" className='pricingtable pricingtable-flat'>
                    <a className="carousel-control-prev" href="/#slideset2" role="button" onClick={(e) => { e.preventDefault(); onPrevClick() }}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} /></a>
                    <div className={'column column-2 store-headers animated first-two-cols-close-subs'} >
                        <div className='column-item column-item-header' >
                            <img loading='lazy' src='https://pandahut.net/wp-content/uploads/2020/10/vipicon_h_3.png' alt='Header Blank Space' className='pricingtable-header-image-hidden' />
                        </div>
                        <div className='column-item column-item-price price-grey' >
                            <span className='price-value'>Price (USD)</span>
                        </div>
                        <div className='column-item column-item-body column-item-header' >
                            <div className='column-item-data column-first-row-empty no-pad' >&nbsp;</div>
                            <div className='column-item-data even'>Duration<a href="#slideset1" onClick={e => e.preventDefault()} className="table-tooltip" data-tip={"Length of time the listed perks are available.\nDurations are <b>non-recurring (must be manually renewed)</b>.\nNo Bamboo removed from your in-game wallet when this period ends."}><FontAwesomeIcon icon={faInfoCircle} /></a></div>
                            <div className='column-item-data odd triple-row'>Chat Tag Color<a href="#slideset1" onClick={e => e.preventDefault()} className="table-tooltip" data-tip={"Valid on all PH servers."}><FontAwesomeIcon icon={faInfoCircle} /></a></div>
                            <div className='column-item-data even main-header-category'>&nbsp;</div>
                            <div className='column-item-data odd'>Reserved Slot<a className="table-tooltip subheader" href="/" data-tip={"The game server will save extra slots for MVP++ members.\nOn select servers, a new slot will be added so you can join, even if the server has reached maximum players."}><FontAwesomeIcon icon={faInfoCircle} /></a></div>
                            <div className='column-item-data even'>Payment Lump Sum <span className="descr-txt-sm" > (Bamboo)</span><a className="table-tooltip subheader" href="/" data-tip="One-time payment."><FontAwesomeIcon icon={faInfoCircle} /></a></div>
                            <div className='column-item-data odd'>Hourly Salary <span className="descr-txt-sm" > (Bamboo)</span><a className="table-tooltip subheader" href="/" data-tip={"Paid 50% every 30 minutes.\nSalary adds on to any existing Permanent Rank"}><FontAwesomeIcon icon={faInfoCircle} /></a></div>
                            <div className='column-item-data even'>Custom Kits<a className="table-tooltip subheader" onClick={e => e.preventDefault()} href="/" data-tip={"Selected from the kits list shown on this page.\nEach kit can be spawned once every two hours.\nKits not available on Roleplay servers (Bamboo only).\nPerks exclude Semi-Vanilla, Vanilla+, and Modded servers."}><FontAwesomeIcon icon={faInfoCircle} /></a></div>
                            <div className='column-item-data odd main-header-category'>&nbsp;</div>
                            <div className='column-item-data even'>Reserved Slot<a className="table-tooltip subheader" href="/" data-tip={"The game server will save extra slots for MVP++ members.\nOn select servers, a new slot will be added so you can join, even if the server has reached maximum players."}><FontAwesomeIcon icon={faInfoCircle} /></a></div>
                            <div className='column-item-data odd'>Payment Lump Sum <span className="descr-txt-sm" > (Bamboo)</span><a className="table-tooltip subheader" href="/" data-tip="One-time payment."><FontAwesomeIcon icon={faInfoCircle} /></a></div>
                            <div className='column-item-data even'>Hourly Salary <span className="descr-txt-sm" > (Bamboo)</span><a className="table-tooltip subheader" href="/" data-tip={"Paid 50% every 30 minutes.\nSalary adds on to any existing Permanent Rank"}><FontAwesomeIcon icon={faInfoCircle} /></a></div>
                            <div className='column-item-data odd'>Custom Kits<a className="table-tooltip subheader" onClick={e => e.preventDefault()} href="/" data-tip={"Selected from the kits list shown on this page.\nEach kit can be spawned once every two hours.\nKits not available on Roleplay servers (Bamboo only)."}><FontAwesomeIcon icon={faInfoCircle} /></a></div>
                            <div className='column-item-data even'>Player Sign Shops<a className="table-tooltip subheader" href="/" data-tip={"Sign Shops allow players to trade with others even when the shop owner is offline. \nTier 1 players (VIP and VIP+) may place 4 additional sign shops, for a total of 8. \nTier 2 players (MVP, MVP+, and MVP++) may place 8 additional sign shops, for a total of 12."}><FontAwesomeIcon icon={faInfoCircle} /></a></div>
                            <div className='column-item-data odd main-header-category'>&nbsp;</div>
                            <div className='column-item-data even'>Reserved Slot<a className="table-tooltip subheader" href="/" data-tip={"The game server will save extra slots for MVP++ members.\nOn select servers, a new slot will be added so you can join, even if the server has reached maximum players."}><FontAwesomeIcon icon={faInfoCircle} /></a></div>
                            <div className='column-item-data odd'>Ranked Apartment Access<a className="table-tooltip subheader" href="/" data-tip={"On Unturnov 1.25 Servers, simply use the main bunker's elevator and get exclusive access to Ranked Apartments.\nUltimate Apartments are regular-sized, exclusive to VIP and VIP+ Members.\nSupreme Apartments are larger and luxurious, exclusive to MVP, MVP+ and MVP++ Members only.\nSupply Limited, available upon every map wipe on a first come, first-serve basis."}><FontAwesomeIcon icon={faInfoCircle} /></a></div>
                            <div className='column-item-data even'>Player Sign Shops<a className="table-tooltip subheader" href="/" data-tip={"Sign Shops allow players to trade with others even when the shop owner is offline. \nTier 1 players (VIP and VIP+) may place 4 additional sign shops, for a total of 8. \nTier 2 players (MVP, MVP+, and MVP++) may place 8 additional sign shops, for a total of 12."}><FontAwesomeIcon icon={faInfoCircle} /></a></div>
                        </div>
                    </div>

                    <div id="mvppp-div" className={'column column-2 first-two-cols-close-subs store-item animated'}>
                        <div className='column-item column-item-header'>
                            <img loading='lazy' src='https://pandahut.net/wp-content/uploads/2021/06/mvpppicon_h8.png' alt={' MVP++ Rank'} className='pricingtable-header-image' />
                            <div className='pricingtable-header-name' >MVP++</div>
                        </div>
                        <div className='column-item column-item-price price-green'>
                            <span className='price-value' >$8.99 USD </span>
                            <span className='pt-pd' >&nbsp;/ Month</span>
                        </div>
                        <div className='column-item column-item-body'>
                            <div className='column-item-data column-first-row-empty no-pad' >&nbsp;</div>
                            <div className='column-item-data even'>
                                <div className="label-sm">
                                    <a href="#slideset2"
                                        onClick={(e) => e.preventDefault()}
                                        className="table-tooltip"
                                        data-tip="Length of time the listed perks are available. No Bamboo removed from your in-game wallet when the period ends.">
                                        Duration <FontAwesomeIcon icon={faInfoCircle} /></a>
                                </div>30 Days
                            </div>
                            <div className='column-item-data odd initial-font triple-row'>
                                <div className="label-sm">
                                    <a href="#slideset2"
                                        onClick={(e) => e.preventDefault()}
                                        className="table-tooltip"
                                        data-tip="Valid on all PH servers.">
                                        Chat Tag Color<FontAwesomeIcon icon={faInfoCircle} /></a>
                                </div>Change your rank's color as any other rank (VIP to MVP+). Default is Gold.
                            </div>
                            <div className='column-item-data even dark-title'>PvP, and OPKits Perks</div>
                            <div className='column-item-data odd no-pad'>
                                <div className="label-sm">
                                    <a href="#slideset2"
                                        onClick={(e) => e.preventDefault()}
                                        className="table-tooltip"
                                        data-tip={"The game server will save extra slots for MVP++ members.\nOn select servers, a new slot will be added so you can join, even if the server is at 24/24 players."}>
                                        Reserved Slot<FontAwesomeIcon icon={faInfoCircle} /></a>
                                </div><FontAwesomeIcon icon={faCheck} />
                            </div>
                            <div className='column-item-data odd subitem'>
                                <div className="label-sm">
                                    <a href="#slideset2"
                                        onClick={(e) => e.preventDefault()}
                                        className="table-tooltip"
                                        data-tip="One-time Bamboo payment per month.">
                                        Payment Lump Sum (Bamboo)<FontAwesomeIcon icon={faInfoCircle} /></a>
                                </div>$250,000 Bamboo/Month
                            </div>
                            <div className='column-item-data even subitem'>
                                <div className="label-sm">
                                    <a href="#slideset2"
                                        onClick={(e) => e.preventDefault()}
                                        className="table-tooltip"
                                        data-tip={"Paid 50% every 30 minutes.\nSalary adds on to any existing Permanent Rank."}>
                                        Hourly Salary (Bamboo)<FontAwesomeIcon icon={faInfoCircle} /></a>
                                </div>$1,000 Bamboo per Hour of Playtime
                            </div>
                            <div className='column-item-data odd subitem' >
                                <div className="label-sm">
                                    <a href="#slideset2"
                                        onClick={(e) => e.preventDefault()}
                                        className="table-tooltip"
                                        data-tip={"Selected from the kits list shown on this page.\nEach kit can be spawned once every two hours.\nKits not available on Roleplay servers (Bamboo only).\nPerks exclude Semi-Vanilla, Vanilla+, and Modded servers."}>
                                        Custom Kits<FontAwesomeIcon icon={faInfoCircle} /></a>
                                </div>Choose four custom kits from the list
                            </div>
                            <div className='column-item-data even dark-title'>Roleplay Server Perks</div>
                            <div className='column-item-data odd no-pad'>
                                <div className="label-sm">
                                    <a href="#slideset2"
                                        onClick={(e) => e.preventDefault()}
                                        className="table-tooltip"
                                        data-tip={"The game server will save extra slots for MVP++ members.\nOn select servers, a new slot will be added so you can join, even if the server is at 24/24 players."}>
                                        Reserved Slot<FontAwesomeIcon icon={faInfoCircle} /></a>
                                </div><FontAwesomeIcon icon={faCheck} />
                            </div>
                            <div className='column-item-data even subitem'>
                                <div className="label-sm">
                                    <a href="#slideset2"
                                        onClick={(e) => e.preventDefault()}
                                        className="table-tooltip"
                                        data-tip="One-time Bamboo payment per month.">
                                        Payment Lump Sum (Bamboo)<FontAwesomeIcon icon={faInfoCircle} /></a>
                                </div>$125,000 Bamboo/Month
                            </div>
                            <div className='column-item-data odd subitem'>
                                <div className="label-sm">
                                    <a href="#slideset2"
                                        onClick={(e) => e.preventDefault()}
                                        className="table-tooltip"
                                        data-tip={"Paid 50% every 30 minutes.\nSalary adds on to any existing Permanent Rank."}>
                                        Hourly Salary (Bamboo)<FontAwesomeIcon icon={faInfoCircle} /></a>
                                </div>$1,000 Bamboo per Hour of Playtime
                            </div>
                            <div className='column-item-data even subitem' >
                                <div className="label-sm">
                                    <a href="#slideset2"
                                        onClick={(e) => e.preventDefault()}
                                        className="table-tooltip"
                                        data-tip={"Selected from the kits list shown on this page.\nEach kit can be spawned once every two hours.\nKits not available on Roleplay servers (Bamboo only)."}>
                                        Custom Kits<FontAwesomeIcon icon={faInfoCircle} /></a>
                                </div>Choose four custom kits from the list
                            </div>
                            <div className='column-item-data odd subitem'>
                                <div className="label-sm">
                                    <a href="#slideset2"
                                        onClick={(e) => e.preventDefault()}
                                        className="table-tooltip"
                                        data-tip="Sign Shops allow players to trade with each other even when the shop owner is offline. \nTier 1 players (VIP and VIP+) may place 4 additional sign shops, for a total of 8. \nTier 2 players (MVP, MVP+, and MVP++) may place 8 additional sign shops, for a total of 12.">
                                        Player Sign Shops<FontAwesomeIcon icon={faInfoCircle} /></a>
                                </div>Tier 2
                            </div>
                            <div className='column-item-data odd dark-title'>Unturnov Server Perks</div>
                            <div className='column-item-data odd no-pad'>
                                <div className="label-sm">
                                    <a href="#slideset2"
                                        onClick={(e) => e.preventDefault()}
                                        className="table-tooltip"
                                        data-tip={"The game server will save extra slots for MVP++ members.\nOn select servers, a new slot will be added so you can join, even if the server is at 24/24 players."}>
                                        Reserved Slot<FontAwesomeIcon icon={faInfoCircle} /></a>
                                </div><FontAwesomeIcon icon={faCheck} />
                            </div>
                            <div className='column-item-data odd subitem'>
                                <div className="label-sm">
                                    <a href="#slideset2"
                                        onClick={(e) => e.preventDefault()}
                                        className="table-tooltip"
                                        data-tip="On Unturnov 1.25 Servers, simply use the main bunker's elevator and get exclusive access to Ranked Apartments.\nUltimate Apartments are regular-sized, exclusive to VIP and VIP+ Members.\nSupreme Apartments are larger and luxurious, exclusive to MVP, MVP+ and MVP++ Members only.\nSupply Limited, available upon every map wipe on a first come, first-serve basis.">
                                        Ranked Apartment Access<FontAwesomeIcon icon={faInfoCircle} /></a>
                                </div>Ultimate & Supreme
                            </div>
                            <div className='column-item-data odd subitem'>
                                <div className="label-sm">
                                    <a href="#slideset2"
                                        onClick={(e) => e.preventDefault()}
                                        className="table-tooltip"
                                        data-tip="Sign Shops allow players to trade with each other even when the shop owner is offline. \nTier 1 players (VIP and VIP+) may place 4 additional sign shops, for a total of 8. \nTier 2 players (MVP, MVP+, and MVP++) may place 8 additional sign shops, for a total of 12.">
                                        Player Sign Shops<FontAwesomeIcon icon={faInfoCircle} /></a>
                                </div>Tier 2
                            </div>
                        </div>
                        <ModalDialog rank={MVPPRankObj} className='Buy-MVP++'/>    
                        </div>
                    <div className="column col-sm-7  store-sub-items-wrapper">
                        <div className="store-sub-items-title column focus-box">Please select four custom kits from the list below. <br /> MVP++ can only be bought if you are MVP+.</div>
                        <aside id="ctup-ads-widget-2" className="column focus-box" data-scrollreveal="enter left after 0.15s over 1s">
                            <div id="kits-cards" className={cards.classList}>
                                {/* <span className="perks-title-bottom">Select&nbsp;<b>four</b>&nbsp;custom kits in the list below</span> */}
                                <div id="kitassault" className={card[0].classList}>
                                    <div className="card-title">
                                        <div className="wrapper-sub-item-btns">
                                            <a name="kitassault" href="#kitassault" className="kit-selector-box">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxes.checkedAssault}
                                                            onClick={handleChange}
                                                            icon={<AddCircleOutlineIcon fontSize="large" />}
                                                            checkedIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                                            name="checkedAssault"
                                                            className="jss1"
                                                        />
                                                    }
                                                    label=""
                                                /></a>
                                            <a href="#slideset2" onClick={(e) => openCard(e, 0)} className="toggle-info">
                                                <span className="left"></span>
                                                <span className="right"></span>
                                            </a>
                                        </div>
                                        <h2 className="wrapper-sub-item-title">Assault Kit</h2>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description" style={{ zIndex: 100 }}>
                                            <div className="pp-contents-txt">
                                                <img  src="https://pandahut.net/subscriptions/images/assaultkit_h1.png" alt="Assault Kit Items" />

                                                <ul align="left">
                                                    <li>1x Honeybadger</li>
                                                    <li>1x 8X Scope</li>
                                                    <li>3x Military Drum</li>
                                                    <li>3x Grenade</li>
                                                    <li>2x Adrenaline</li>
                                                    <li>3x Medkit</li>
                                                    <li>1x Alice Pack</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="kitThief" className={card[1].classList}>
                                    <div className="card-title">
                                        <div className="wrapper-sub-item-btns">
                                            <a name="kitThief" href="#kitThief" className="kit-selector-box">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxes.checkedThief}
                                                            onClick={handleChange}
                                                            icon={<AddCircleOutlineIcon fontSize="large" />}
                                                            checkedIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                                            name="checkedThief"
                                                        />
                                                    }
                                                    label=""
                                                /></a>
                                            <a href="#slideset2" onClick={(e) => openCard(e, 1)} className="toggle-info">
                                                <span className="left"></span>
                                                <span className="right"></span>
                                            </a>
                                        </div>
                                        <h2 className="wrapper-sub-item-title">Thief Kit</h2>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description" style={{ zIndex: 100 }}>
                                            <div className="pp-contents-txt">
                                                <img loading='lazy' src="https://pandahut.net/subscriptions/images/banditkit_h1.png" alt="Thief Kit Items" />

                                                <ul align="left">
                                                    <li>1x Bluntforce</li>
                                                    <li>3x 12-Gauge Shells</li>
                                                    <li>2x C4 Sticky Grenade</li>
                                                    <li>1x Sledgehammer</li>
                                                    <li>3x SutureKit</li>
                                                    <li>1x Alice Pack</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div id="kitmedic" className={card[2].classList}>
                                    <div className="card-title">
                                        <div className="wrapper-sub-item-btns">
                                            <a name="kitmedic" href="#kitmedic" className="kit-selector-box">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxes.checkedMedic}
                                                            onClick={handleChange}
                                                            icon={<AddCircleOutlineIcon fontSize="large" />}
                                                            checkedIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                                            name="checkedMedic"
                                                        />
                                                    }
                                                    label=""
                                                /></a>
                                            <a href="#slideset2" onClick={(e) => openCard(e, 2)} className="toggle-info">
                                                <span className="left"></span>
                                                <span className="right"></span>
                                            </a>
                                        </div>
                                        <h2 className="wrapper-sub-item-title">Medic Kit</h2>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description" style={{ zIndex: 100 }}>
                                            <div className="pp-contents-txt">
                                                <img loading='lazy' src="https://pandahut.net/subscriptions/images/medickit_h.png" alt="Medic Kit Items" />

                                                <ul align="left">
                                                    <li>1x Determinator</li>
                                                    <li>3x 12-Gauge Shells</li>
                                                    <li>3x Medkit</li>
                                                    <li>3x Bandage</li>
                                                    <li>3x Morphine</li>
                                                    <li>3x Vaccine</li>
                                                    <li>1x Alice Pack</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="kitrunner" className={card[3].classList}>
                                    <div className="card-title">
                                        <div className="wrapper-sub-item-btns">
                                            <a name="kitrunner" href="#kitrunner" className="kit-selector-box">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxes.checkedRunner}
                                                            onClick={handleChange}
                                                            icon={<AddCircleOutlineIcon fontSize="large" />}
                                                            checkedIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                                            name="checkedRunner"
                                                        />
                                                    }
                                                    label=""
                                                /></a>
                                            <a href="#slideset2" onClick={(e) => openCard(e, 3)} className="toggle-info">
                                                <span className="left"></span>
                                                <span className="right"></span>
                                            </a>
                                        </div>
                                        <h2 className="wrapper-sub-item-title">Runner Kit</h2>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description" style={{ zIndex: 100 }}>
                                            <div className="pp-contents-txt">
                                                <img loading='lazy' src="https://pandahut.net/subscriptions/images/runnerkit_h.png" alt="Runner Kit Items" />

                                                <ul align="left">
                                                    <li>1x Augewehr </li>
                                                    <li>3x Ranger Drum</li>
                                                    <li>1x Avenger </li>
                                                    <li>2x Avenger Magazine</li>
                                                    <li>1x Red-Dot Sight </li>
                                                    <li>2x Suture Kit </li>
                                                    <li>2x Large Metal Plate </li>
                                                    <li>1x Alice Pack</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div id="kitflanker" className={card[4].classList}>
                                    <div className="card-title">
                                        <div className="wrapper-sub-item-btns">
                                            <a name="kitflanker" href="#kitflanker" className="kit-selector-box">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxes.checkedFlanker}
                                                            onClick={handleChange}
                                                            icon={<AddCircleOutlineIcon fontSize="large" />}
                                                            checkedIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                                            name="checkedFlanker"
                                                        />
                                                    }
                                                    label=""
                                                /></a>
                                            <a href="#slideset2" onClick={(e) => openCard(e, 4)} className="toggle-info">
                                                <span className="left"></span>
                                                <span className="right"></span>
                                            </a>
                                        </div>
                                        <h2 className="wrapper-sub-item-title">Flanker Kit</h2>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description" style={{ zIndex: 100 }}>
                                            <div className="pp-contents-txt">
                                                <img loading='lazy' src="https://pandahut.net/subscriptions/images/flankerkit_h.png" alt="Flanker Kit Items" />

                                                <ul align="left">
                                                    <li>1x Calling Card</li>
                                                    <li>2x Calling Card Magazine</li>
                                                    <li>1x Military Nightvision</li>
                                                    <li>2x Medkit</li>
                                                    <li>2x Adrenaline</li>
                                                    <li>2x Large Metal Plate</li>
                                                    <li>1x Alice Pack</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div id="kithooligan" className={card[5].classList}>
                                    <div className="card-title">
                                        <div className="wrapper-sub-item-btns">
                                            <a name="kithooligan" href="#kithooligan" className="kit-selector-box">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxes.checkedHooligan}
                                                            onClick={handleChange}
                                                            icon={<AddCircleOutlineIcon fontSize="large" />}
                                                            checkedIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                                            name="checkedHooligan"
                                                        />
                                                    }
                                                    label=""
                                                /></a>
                                            <a href="#slideset2" onClick={(e) => openCard(e, 5)} className="toggle-info">
                                                <span className="left"></span>
                                                <span className="right"></span>
                                            </a>
                                        </div>
                                        <h2 className="wrapper-sub-item-title">Hooligan Kit</h2>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description" style={{ zIndex: 100 }}>
                                            <div className="pp-contents-txt">
                                                <img loading='lazy' src="https://pandahut.net/subscriptions/images/hooligankit_h.png" alt="Hooligan Kit Items" />

                                                <ul align="left">
                                                    <li>1x Scalar</li>
                                                    <li>2x Scalar Magazine</li>
                                                    <li>1x Ace</li>
                                                    <li>2x Ace Clip</li>
                                                    <li>1x Demolition Charge</li>
                                                    <li>1x Remote Detonator</li>
                                                    <li>2x Grenade</li>
                                                    <li>5x Bandage</li>
                                                    <li>1x Alice Pack</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div id="kightls" className={card[6].classList}>
                                    <div className="card-title">
                                        <div className="wrapper-sub-item-btns">
                                            <a name="kightls" href="#kightls" className="kit-selector-box">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxes.checkedLightSniper}
                                                            onClick={handleChange}
                                                            icon={<AddCircleOutlineIcon fontSize="large" />}
                                                            checkedIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                                            name="checkedLightSniper"
                                                        />
                                                    }
                                                    label=""
                                                /></a>
                                            <a href="#slideset2" onClick={(e) => openCard(e, 6)} className="toggle-info">
                                                <span className="left"></span>
                                                <span className="right"></span>
                                            </a>
                                        </div>
                                        <h2 className="wrapper-sub-item-title">Light Sniper Kit</h2>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description" style={{ zIndex: 100 }}>
                                            <div className="pp-contents-txt">
                                                <img loading='lazy' src="https://pandahut.net/subscriptions/images/lightsniperkit_h.png" alt="LightSniper Kit Items" />

                                                <ul align="left">
                                                    <li>1x Timberwolf</li>
                                                    <li>3x Timberwolf Magazine</li>
                                                    <li>1x Luger</li>
                                                    <li>2x LC Civilian Ammunition Box</li>
                                                    <li>1x 16X Scope</li>
                                                    <li>1x RangeFinder</li>
                                                    <li>1x Bipod</li>
                                                    <li>2x Medkit</li>
                                                    <li>3x MRE</li>
                                                    <li>1x Alice Pack</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div id="kitforest" className={card[7].classList}>
                                    <div className="card-title">
                                        <div className="wrapper-sub-item-btns">
                                            <a name="kitforest" href="#kitforest" className="kit-selector-box">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxes.checkedForest}
                                                            onClick={handleChange}
                                                            icon={<AddCircleOutlineIcon fontSize="large" />}
                                                            checkedIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                                            name="checkedForest"
                                                        />
                                                    }
                                                    label=""
                                                /></a>
                                            <a href="#slideset2" onClick={(e) => openCard(e, 7)} className="toggle-info">
                                                <span className="left"></span>
                                                <span className="right"></span>
                                            </a>
                                        </div>
                                        <h2 className="wrapper-sub-item-title">Forest Kit</h2>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description" style={{ zIndex: 100 }}>
                                            <div className="pp-contents-txt">
                                                <img loading='lazy' src="https://pandahut.net/subscriptions/images/forestkit_h.png" alt="Forest Kit Items" />

                                                <ul align="left">
                                                    <li>1x Eaglefire</li>
                                                    <li>3x Military Drum</li>
                                                    <li>1x Adaptive Chambering</li>
                                                    <li>3x C4 Sticky Grenade</li>
                                                    <li>1x Forest Military Top</li>
                                                    <li>1x Forest Military Bottom</li>
                                                    <li>1x Forest Military Vest</li>
                                                    <li>1x Forest Military Helmet</li>
                                                    <li>3x MRE</li>
                                                    <li>1x Alice Pack</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div id="kitsneaky" className={card[8].classList}>
                                    <div className="card-title">
                                        <div className="wrapper-sub-item-btns">
                                            <a name="kitsneaky" href="#kitsneaky" className="kit-selector-box">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxes.checkedSneaky}
                                                            onClick={handleChange}
                                                            icon={<AddCircleOutlineIcon fontSize="large" />}
                                                            checkedIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                                            name="checkedSneaky"
                                                        />
                                                    }
                                                    label=""
                                                /></a>
                                            <a href="#slideset2" onClick={(e) => openCard(e, 8)} className="toggle-info">
                                                <span className="left"></span>
                                                <span className="right"></span>
                                            </a>
                                        </div>
                                        <h2 className="wrapper-sub-item-title">Sneaky Kit</h2>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description" style={{ zIndex: 100 }}>
                                            <div className="pp-contents-txt">
                                                <img loading='lazy' src="https://pandahut.net/subscriptions/images/sneakykit_h.png" alt="Sneaky Kit Items" />

                                                <ul align="left">
                                                    <li>1x Honeybadger</li>
                                                    <li>2x Military Drum</li>
                                                    <li>1x Ekho</li>
                                                    <li>5x Ekho Magazine</li>
                                                    <li>1x 16X Scope</li>
                                                    <li>1x Rangefinder</li>
                                                    <li>1x Bipod</li>
                                                    <li>1x Forest Military Top</li>
                                                    <li>1x Forest Military Bottom</li>
                                                    <li>1x Forest Military Vest</li>
                                                    <li>1x Forest Military Helmet</li>
                                                    <li>2x Medkit</li>
                                                    <li>1x Alice Pack</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div id="kitstalker" className={card[9].classList}>
                                    <div className="card-title">
                                        <div className="wrapper-sub-item-btns">
                                            <a name="kitstalker" href="#kitstalker" className="kit-selector-box">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxes.checkedStalker}
                                                            onClick={handleChange}
                                                            icon={<AddCircleOutlineIcon fontSize="large" />}
                                                            checkedIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                                            name="checkedStalker"
                                                        />
                                                    }
                                                    label=""
                                                /></a>
                                            <a href="#slideset2" onClick={(e) => openCard(e, 9)} className="toggle-info">
                                                <span className="left"></span>
                                                <span className="right"></span>
                                            </a>
                                        </div>
                                        <h2 className="wrapper-sub-item-title">Stalker Kit</h2>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description" style={{ zIndex: 100 }}>
                                            <div className="pp-contents-txt">
                                                <img loading='lazy' src="https://pandahut.net/subscriptions/images/stalkerkit_h.png" alt="Stalker Kit Items" />

                                                <ul align="left">
                                                    <li>1x Nightraider</li>
                                                    <li>3x Ranger Drum</li>
                                                    <li>1x Ranger Supressor</li>
                                                    <li>1x Teklowvka</li>
                                                    <li>2x Teklowvka Magazine</li>
                                                    <li>1x Civilian Nightvision</li>
                                                    <li>1x Forest Military Top</li>
                                                    <li>1x Forest Military Bottom</li>
                                                    <li>1x Forest Military Vest</li>
                                                    <li>1x Forest Military Helmet</li>
                                                    <li>1x Alice Pack</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div id="kitboom" className={card[10].classList}>
                                    <div className="card-title">
                                        <div className="wrapper-sub-item-btns">
                                            <a name="kitboom" href="#kitboom" className="kit-selector-box">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxes.checkedBoom}
                                                            onClick={handleChange}
                                                            icon={<AddCircleOutlineIcon fontSize="large" />}
                                                            checkedIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                                            name="checkedBoom"
                                                        />
                                                    }
                                                    label=""
                                                /></a>
                                            <a href="#slideset2" onClick={(e) => openCard(e, 10)} className="toggle-info">
                                                <span className="left"></span>
                                                <span className="right"></span>
                                            </a>
                                        </div>
                                        <h2 className="wrapper-sub-item-title">Boom Kit</h2>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description" style={{ zIndex: 100 }}>
                                            <div className="pp-contents-txt">
                                                <img loading='lazy' src="https://pandahut.net/subscriptions/images/boomkit_h.png" alt="Boom Kit Items" />

                                                <ul align="left">
                                                    <li>1x Honeybadger</li>
                                                    <li>3x Military Drum</li>
                                                    <li>1x 8X Scope</li>
                                                    <li>1x Adaptive Chambering</li>
                                                    <li>3x C4 Sticky Grenade</li>
                                                    <li>3x Grenade</li>
                                                    <li>3x Demolition Charge</li>
                                                    <li>1x Remote Detonator</li>
                                                    <li>1x Alice Pack</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div id="kiths" className={card[11].classList}>
                                    <div className="card-title">
                                        <div className="wrapper-sub-item-btns">
                                            <a name="kiths" href="#kiths" className="kit-selector-box">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxes.checkedHeavySniper}
                                                            onClick={handleChange}
                                                            icon={<AddCircleOutlineIcon fontSize="large" />}
                                                            checkedIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                                            name="checkedHeavySniper"
                                                        />
                                                    }
                                                    label=""
                                                /></a>
                                            <a href="#slideset2" onClick={(e) => openCard(e, 11)} className="toggle-info">
                                                <span className="left"></span>
                                                <span className="right"></span>
                                            </a>
                                        </div>
                                        <h2 className="wrapper-sub-item-title">Heavy Sniper Kit</h2>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description" style={{ zIndex: 100 }}>
                                            <div className="pp-contents-txt">
                                                <img loading='lazy' src="https://pandahut.net/subscriptions/images/heavysniperkit_h1.png" alt="HeavySniper Kit Items" />

                                                <ul align="left">
                                                    <li>1x Grizzly</li>
                                                    <li>3x Grizzly Magazine</li>
                                                    <li>1x Maschinengewehr (MP40)</li>
                                                    <li>2x Maschinengewehr Magazine</li>
                                                    <li>1x 16X Scope</li>
                                                    <li>1x Bipod</li>
                                                    <li>1x RangeFinder</li>
                                                    <li>2x Medkit</li>
                                                    <li>3x MRE</li>
                                                    <li>1x Alice Pack</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div id="sskit" className={card[12].classList}>
                                    <div className="card-title">
                                        <div className="wrapper-sub-item-btns">
                                            <a name="sskit" href="#sskit" className="kit-selector-box">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxes.checkedSharpShooter}
                                                            onClick={handleChange}
                                                            icon={<AddCircleOutlineIcon fontSize="large" />}
                                                            checkedIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                                            name="checkedSharpShooter"
                                                        />
                                                    }
                                                    label=""
                                                /></a>
                                            <a href="#slideset2" onClick={(e) => openCard(e, 12)} className="toggle-info">
                                                <span className="left"></span>
                                                <span className="right"></span>
                                            </a>
                                        </div>
                                        <h2 className="wrapper-sub-item-title">Sharpshooter Kit</h2>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description" style={{ zIndex: 100 }}>
                                            <div className="pp-contents-txt">
                                                <img loading='lazy' src="https://pandahut.net/subscriptions/images/sharpshooterkit_h.png" alt="Sharpshooter Kit Items" />

                                                <ul align="left">
                                                    <li>1x Heartbreaker</li>
                                                    <li>3x Military Drum</li>
                                                    <li>1x 16X Scope</li>
                                                    <li>1x Rangefinder</li>
                                                    <li>1x Bipod</li>
                                                    <li>1x Binoculars</li>
                                                    <li>1x Civilian Nightvision</li>
                                                    <li>1x Forest Military Top</li>
                                                    <li>1x Forest Military Bottom</li>
                                                    <li>1x Forest Military Vest</li>
                                                    <li>1x Forest Military Helmet</li>
                                                    <li>2x Medkit</li>
                                                    <li>1x Alice Pack</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div id="kitsnooper" className={card[13].classList}>
                                    <div className="card-title">
                                        <div className="wrapper-sub-item-btns">
                                            <a name="kitsnooper" href="#kitsnooper" className="kit-selector-box">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxes.checkedSnooper}
                                                            onClick={handleChange}
                                                            icon={<AddCircleOutlineIcon fontSize="large" />}
                                                            checkedIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                                            name="checkedSnooper"
                                                        />
                                                    }
                                                    label=""
                                                /></a>
                                            <a href="#slideset2" onClick={(e) => openCard(e, 13)} className="toggle-info">
                                                <span className="left"></span>
                                                <span className="right"></span>
                                            </a>
                                        </div>
                                        <h2 className="wrapper-sub-item-title">Snooper Kit</h2>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description" style={{ zIndex: 100 }}>
                                            <div className="pp-contents-txt">
                                                <img loading='lazy' src="https://pandahut.net/subscriptions/images/snooperkit_h.png" alt="Snooper Kit Items" />

                                                <ul align="left">
                                                    <li>1x Matamorez</li>
                                                    <li>5x Matamorez Box</li>
                                                    <li>1x Maschinengewehr (MP40)</li>
                                                    <li>2x Maschinengewehr Magazine</li>
                                                    <li>1x 16X Scope</li>
                                                    <li>1x Rangefinder</li>
                                                    <li>1x Bipod</li>
                                                    <li>1x Binoculars</li>
                                                    <li>1x Forest Military Top</li>
                                                    <li>1x Forest Military Bottom</li>
                                                    <li>1x Forest Military Vest</li>
                                                    <li>1x Forest Military Helmet</li>
                                                    <li>2x Bandage</li>
                                                    <li>1x Alice Pack</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>

                    {/* <div className="focus-notes-top">
                <ul style={{ listStyleType: "none" }}>
                    <li><h6 style={{ margin: 0 }}><div style={{ color: "#355A82", fontSize: "16px", padding: "3px 0 3px" }}>Your in-game wallet is the same on all Pandahut PvP &amp; Modded <a target="_blank" href="https://pandahut.net/servers"><u>Servers</u>&nbsp;</a>. KitPvP/OPKits Servers use a separate economy.</div></h6>

                    </li><li><h6 style={{ margin: 0 }}><div style={{ color: "#355A82", fontSize: "16px", padding: "3px 0 3px" }}><strong>Multiple concurrent items and/or subscriptions do not stack up. Your subscription can be cancelled at any time.</strong></div></h6></li>
                    <li><h6 style={{ margin: 0 }}><div style={{ color: "#355A82", fontSize: "16px", padding: "3px 0 3px" }}>Enter your correct <a className="steamidlink" target="_blank" href="https://steamid.io/">SteamID64</a> below and claim your in-game perks as soon as your order is complete.</div></h6></li>
                    <li><h6 style={{ margin: 0 }}><div style={{ color: "#355A82", fontSize: "16px", padding: "3px 0 3px" }}>All purchases are subject to our <a className="steamidlink" target="_blank" href="https://pandahut.net/UserAgreement">User Agreement</a>.</div></h6></li>
                </ul>
                <ul style={{ listStyleType: "none" }}>
                    <li style={{ fontSize: "12px", color: "#000000" }}> You must enter a <b>valid</b> SteamID64 in the form below for your order to be processed automatically. Invalid SteamID64's will be processed manually. </li>
                    <li style={{ fontSize: "12px", color: "#000000" }}> If you are currently playing in a Pandahut server, you must first <b>reconnect</b> to the server in order to claim your perks.</li>
                    <li style={{ fontSize: "12px", color: "#000000" }}> Please make sure to enter <b>your own</b> correct <a className="steamidlink" target="_blank" href="https://steamid.io/">SteamID64</a>, in the following format: <i style={{color: "#0364e0"}}>7656119xxxxxxxxxx</i></li>
                </ul>
            </div>
            <div className="purchase-box-title">Payment Options</div>
            <div className="storewrapper">
                <div className="pp-div pp-purchase-box" id="subranks">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="YWBD3AA48SNFW" />
                        <table>
                            <tbody><tr><td><input type="hidden" name="on0" value="Select your desired Member Kits" />One-Time Payment<br /><sup>Lasts 30 days<sup></sup></sup></td></tr>
                                <tr><td><select id="json-one1" name="os0">
                                    <option value="base">Select Your Desired Option</option>
                                    <option value="Baby Panda"> Baby Panda &nbsp; &nbsp; &nbsp;$5,00 USD</option>
                                    <option value="Mighty Panda"> Mighty Panda &nbsp; &nbsp;$10,00 USD</option>
                                    <option value="Master Panda"> Master Panda &nbsp; &nbsp;$15,00 USD</option>
                                </select> </td></tr>
                                <tr><td><input type="hidden" name="on2" value="Items" />
                                    <select name="os2" id="json-two1" style={{display: "none"}}>
                                            <option value="">Select Your Member Kit #1</option>
                                </select></td></tr>
                        <tr><td><input type="hidden" name="on3" value="Items2" />
                            <select name="os3" id="json-three1" style={{display: "none"}}>
                                    <option value = "">Select Your Member Kit #2</option>
                        </select></td></tr>
                <tr><td><input type="hidden" name="on4" value="Items3" />
                    <select name="os4" id="json-four1" style={{display: "none"}}>
                            <option value = "">Select Your Member Kit #3</option>
                </select></td></tr>
        <tr><td><input type="hidden" name="on5" value="Items4" />
            <select name="os5" id="json-five1" style={{display: "none"}}>
                    <option value = "">Select Your Member Kit #4</option>
        </select></td></tr >
            <tr><td><input type="hidden" name="on6" value="Items5" />
                <select name="os6" id="json-six1" style={{
                    display: "none"}}>
                        <option value = "">Select Your Member Kit #5</option>
            </select></td ></tr >
                <tr><td><input type="hidden" name="on7" value="Items6" />
                    <select name="os7" id="json-seven1" style={{
                        display: "none"}}>
                            <option value = "">Select Your Member Kit #6</option>
                </select></td ></tr >
    <tr><td><input type="hidden" name="on1" value="SteamID64" />Enter Your 17-digit <a className="steamidlink" target="_parent" href="https://pandahut.net/steamid64/">SteamID64</a></td></tr>
    <tr><td><input type="text" className="steamid" name="os1" maxlength="200" value="" /></td></tr>
    <tr><td><sub>For instructions on finding your ID, click <a className="steamidlink" target="_parent" href="https://pandahut.net/steamid64/">Here</a> or</sub>
    <div style={{color: "rgb(31,33,36)"}}>
        <div className="steamlogin" onclick="document.getElementById('steamloginbtn').click()"></div></div></td></tr>    
    </tbody></table>
    <input type="hidden" name="currency_code" value="USD" />
    
    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
    <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" r75t146mg="" />
    </form >
    </div >
    <div className="pp-div pp-purchase-box" id="subranks">
    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
    <input type="hidden" name="cmd" value="_s-xclick" />
    <input type="hidden" name="hosted_button_id" value="8VAFR8BV4VXNU" />
    <table>
    <tbody>
    <tr><td><input type="hidden" name="on0" value="Select your desired subscription" />Membership Subscription<br /><sup>Charged Monthly<sup></sup></sup></td></tr>
    <tr><td><select id="json-one" name="os0">
    <option value="base">Select Your Desired Option</option>
    <option value="Baby Panda">Baby Panda &nbsp; &nbsp; &nbsp;$5,00 USD / Month</option>
    <option value="Mighty Panda">Mighty Panda &nbsp; &nbsp;$10,00 USD / Month</option>
    <option value="Master Panda">Master Panda &nbsp; &nbsp;$15,00 USD / Month</option>
    </select> </td></tr>
    <tr><td><input type="hidden" name="on2" value="Items" />
    <select name="os2" id="json-two" style={{display: "none"}}>
    <option value="">Select Your Member Kit #1</option>
    </select></td></tr>
    <tr><td><input type="hidden" name="on3" value="Items2" />
    <select name="os3" id="json-three" style={{display: "none"}}>
    <option value="">Select Your Member Kit #2</option>
    </select></td></tr>
    <tr><td><input type="hidden" name="on4" value="Items3" />
    <select name="os4" id="json-four" style={{display: "none"}}>
    <option value="">Select Your Member Kit #3</option>
    </select></td ></tr >
        <tr><td><input type="hidden" name="on5" value="Items4" />
            <select name="os5" id="json-five" style={{
                display: "none"}}>
                    <option value = "">Select Your Member Kit #4</option>
        </select></td ></tr >
            <tr><td><input type="hidden" name="on6" value="Items5" />
                <select name="os6" id="json-six" style={{
                    display: "none"}}>
                        <option value = "">Select Your Member Kit #5</option>
            </select></td ></tr >
                <tr><td><input type="hidden" name="on7" value="Items6" />
                    <select name="os7" id="json-seven" style={{
                        display: "none"}}>
                            <option value = "">Select Your Member Kit #6</option>
                </select></td ></tr >
    <tr><td><input type="hidden" name="on1" value="SteamID64" />Enter Your 17-digit <a className="steamidlink" target="_parent" href="https://pandahut.net/steamid64/">SteamID64</a></td></tr>
    <tr><td><input type="text" className="steamid" name="os1" maxlength="200" value="" /></td></tr>
    <tr><td><sub>For instructions on finding your ID, click <a className="steamidlink" target="_parent" href="https://pandahut.net/steamid64/">Here</a> or</sub></td></tr>
    <tr><td><div style={{color: "rgb(31,33,36)"}}><div className="steamlogin" onclick="document.getElementById('steamloginbtn').click()"></div></div></td></tr>
    </tbody>
    </table>
    <input type="hidden" name="currency_code" value="USD" />
    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
    <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" r75t146mg="" /><br />
    <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_subscr-find&amp;alias=8VAFR8BV4VXNU"><img loading='lazy' src="https://www.paypalobjects.com/en_US/i/btn/btn_unsubscribe_SM.gif" border="0" /></a>
    <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" r75t146mg="" />

    </form >
    </div >
    <div className="pp-div" style={{display: "none"}}>
    Don't know your SteamID64?
    <form action="" method="post" id="steamlg">
    <input type="hidden" name="openid_identifier" value="https://steamcommunity.com/openid" />
    <button type="submit" form="steamlg" value="Submit" className="steamlogin" id="steamloginbtn"></button>
    </form >
    </div >
        <a name="payment">&nbsp;</a>
    </div > */}
                    <ReactTooltip className='tooltip-node tip-list' delayHide={1000} effect='solid' />
                </div>
                </div>
            </div>
        </div>
    </div>
}
