import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faInstagram, faTwitch, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'


export default function FooterSection() {
    return <section id="footer" className="footer-area footer-dark">
    <div className="container">
        <div className="row justify-content-center footer-row">
            <div className="col-lg-6 media-icons-wrapper">
                <div className="col-md-3 company-details">
                    <div className="icon-top red-text"><a className="p-0" href="mailto:contact@pandahut.net"><img loading='lazy' src="https://pandahut.net/wp-content/uploads/2017/08/ph-email-icon-footer_k.png" alt="Email Us" /></a></div><a href="mailto:contact@pandahut.net">contact@pandahut.net</a></div>
                <div className="col-md-3 company-details">
                    <div className="icon-top green-text"><a className="p-0" href="https://store.steampowered.com/app/304930/"><img loading='lazy' src="https://pandahut.net/wp-content/uploads/2017/04/Unturned.jpg" alt="Get Uturned" /></a></div><a href="https://store.steampowered.com/app/304930/">Check out Unturned!</a></div>
                <div className="col-md-3 company-details">
                    <div className="icon-top blue-text"><a className="p-0" target="_blank" href="https://pandahut.net/discord/" rel="noopener noreferrer"><img loading='lazy' src="https://pandahut.net/wp-content/uploads/2017/06/discord.png" alt="Pandahut Discord" /></a></div><a href="https://pandahut.net/discord/">Join us on Discord!</a></div>
                <div className="col-md-3 copyright">
                    <ul className="social">
                        <li><a target="_blank" href="https://pandahut.net/discord" rel="noopener noreferrer"><FontAwesomeIcon icon={faDiscord} /></a></li>
                        <li><a target="_blank" href="https://www.youtube.com/channel/UC3uzAb6fG87XPfSsOenDILA" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} /></a></li>
                        <li><a target="_blank" href="https://twitter.com/PandahutNetwork" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/pandahut.network/" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a></li>
                        <li><a target="_blank" href="https://www.twitch.tv/pandahutnetwork" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitch} /></a></li>
                    </ul>
                    <p><sup className="footer-sup">
                            <span>©Pandahut Network 2015-{new Date().getFullYear()}</span><br />
                            Unturned is © <a href="https://smartlydressedgames.com">Smartly Dressed Games</a><br />
                            This website is not affiliated with <a href="https://smartlydressedgames.com">SDG</a><br />
                            Pandahut Support: <a href="mailto:contact@pandahut.net" className="footer-email-contact">contact@pandahut.net</a><br />
                            <a href="https://pandahut.net/UserAgreement">User Agreement</a> | <a href="https://pandahut.net/UserAgreement">Privacy Policy</a>
                        </sup></p>
                </div>
            </div>
        </div>
    </div>
</section>
}
