import React, {useEffect} from 'react';
import { TryGetFrontPageInfo } from "../Helpers/ResolvePlayerStats";
import { TryGetDiscordInviteInfo } from "../Helpers/ResolveDiscordInfo";


export default function AboutUsSection() {

    const [serverInfo, setServerInfo] = React.useState({ Servers: "Loading..", DailyUniquePlayers: "Loading..", MonthlyUniquePlayers: "Loading..", MonthlySessions: "Loading..", Loaded: false });

    
    const [discordInfo, setDiscordInfo] = React.useState({ Members: "Loading..." , Loaded: false});
    
  const GetServerInfo = () => {
      // don't fire if loaded. Also we have to check if the doucument is null due to SSR (server-side rendering) rendering ahead of time.
    if (serverInfo.Loaded === false && typeof document !== "undefined")
    {
    TryGetFrontPageInfo().then(frontPageInfo => setServerInfo({  Servers: frontPageInfo.Servers.toLocaleString(undefined), DailyUniquePlayers:  frontPageInfo.DailyUniquePlayers.toLocaleString(undefined), MonthlyUniquePlayers:  frontPageInfo.MonthlyUniquePlayers.toLocaleString(undefined), MonthlySessions: frontPageInfo.MonthlySessions.toLocaleString(undefined), Loaded: true}));
    if (discordInfo.Loaded === false) {
        TryGetDiscordInviteInfo().then(discordInviteInfo => setDiscordInfo({ Members: `${discordInviteInfo.approximate_member_count.toLocaleString()} Members`, Loaded: true }))
    }
    }
}

  useEffect(() => {
    GetServerInfo();
  } );

return <section id="aboutus" className="features-area">
<div className="white-semi-tr bg-filter">
    <div className="container about-us-container">
        <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10">
                <div className="section-title text-center pb-10">
                    <h3 className="title">About Us</h3>
                    <p className="text">General information about the services we offer.</p>
                </div>
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-lg-4 col-md-7 col-sm-9 about-us-box-item">
                <div className="single-features">
                    <div className="features-title-icon d-flex justify-content-between">
                        <div className="features-icon">
                            <img loading='lazy' className="about-us-descr-img about-us-stats-img" title="Unturned Servers" src="https://pandahut.net/wp-content/uploads/2021/06/about-us-unturned-icon_sm.png" alt="Unturned" />
                        </div>
                        <h4 className="features-title">PH Unturned Servers</h4>
                    </div>
                    <div className="features-content">
                        <p className="text">• Unturned is an zombie-survival game by <a target="_blank" href="https://smartlydressedgames.com" rel="noopener noreferrer">SDG Games</a> featuring a rich open-world multiplayer experience.<br /><br />
                        • Pandahut Network hosts a variety of free-to-use dedicated <a target="_blank" href="https://servers.pandahut.net/" rel="noopener noreferrer">servers</a> for Unturned, which operate under agreement with the official game <a target="_blank" href="https://smartlydressedgames.com" rel="noopener noreferrer">publisher</a>.</p>                     
                        <a className="features-btn" href="https://pandahut.net/servers/">PH UNTURNED SERVERS</a>
                    </div>
                </div>
            </div>
            
            <div className="col-lg-4 col-md-7 col-sm-9 about-us-box-item">
                <div className="single-features">                    
                    <div className="features-title-icon d-flex justify-content-between">
                        <div className="info-pie-chart-wrap">
                            <div className="about-us-descr-img about-us-pie-chart"><img loading='lazy' src="https://pandahut.net/wp-content/uploads/2021/06/99pi-200x200-1.png" alt="99.9% Uptime Pie Chart" /></div>
                        </div>
                        <div className="about-us-chartinfo">
                            <h4 className="features-title">99.9% Server Uptime</h4>
                        </div>
                    </div>
                    <div className="features-content">
                        <p className="text">• Visit our <a target="_blank" href="https://pandahut.net/status/" rel="noopener noreferrer">Server Status</a> page for more stats and graphs on our servers and services.<br /><br />
                        • Currently hosting {serverInfo.Servers} Unturned servers, PH is home to {serverInfo.DailyUniquePlayers} daily players, {serverInfo.MonthlyUniquePlayers} unique monthly players and {serverInfo.MonthlySessions} Monthly Player Sessions.</p>                     
                        <a className="features-btn" href="https://pandahut.net/status/">PH SERVER STATUS</a>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-7 col-sm-9 about-us-box-item">
                <div className="single-features">
                    <div className="features-title-icon d-flex justify-content-between">
                        <div className="features-icon">
                            <img loading='lazy' className="about-us-descr-img about-us-stats-img" title="Founded in 2015" src="https://pandahut.net/wp-content/uploads/2021/06/about-us-podium_sm.png" alt="PH Stats" />
                        </div>
                        <h4 className="features-title">Community Leaderboards</h4>
                    </div>
                    <div className="features-content">
                        <p className="text">• Compare your player stats and see how you rank among PH server players.<br /><br /> 
                        • Check out your K/D stats, headshots, items picked up, and more, for all different Unturned game modes spanning multiple Pandahut servers.</p>
                        <a className="features-btn" href="https://stats.pandahut.net">SEARCH PLAYER STATS</a>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-7 col-sm-9 about-us-box-item">
                <div className="single-features">
                    <div className="features-title-icon d-flex justify-content-between">
                        <div className="features-icon">
                            <img loading='lazy' className="about-us-descr-img about-us-stats-img" title="Founded in 2015" src="https://pandahut.net/wp-content/uploads/2021/06/about-us-calendar.png" alt="Calendar" />
                        </div>
                        <h4 className="features-title">Our Legacy</h4>
                    </div>
                    <div className="features-content">
                        <div className="text">• Hosting Unturned servers since 2015, Pandahut (PH) counts on an advanced toolset and experience in community management, server hosting, and game integration.<br /><br />
                        • PH counts on a global CDN for speedier access to our web resources, even in-game.<br /><br />
                        • All server data is stored in multiple redundant layers - in the event of server hardware failure, you won't lose your game progress.<br /><br />
                        • Pandahut currently hosts servers located in the USA East Coast, USA West Coast, and Central Europe.</div>   
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-7 col-sm-9 about-us-box-item">
                <div className="single-features">
                    <div className="features-title-icon d-flex justify-content-between">
                        <div className="features-icon">
                            <img loading='lazy' className="about-us-descr-img about-us-discord-logo" title="Discord" src="https://pandahut.net/wp-content/uploads/2021/06/discord-logo-134E148657-seeklogo.com_.png" alt="Discord Logo" />
                        </div>
                        <h4 className="features-title">Join us on Discord!</h4>
                    </div>
                    <div className="features-content">
                        <p className="text">• At {discordInfo.Members}, our Discord community is the largest group of Pandahut members in a single public online channel.</p>
                        <div className="about-us-col-item col-display">
                            <h5 className="features-title">Discord Integration</h5>
                            <img loading='lazy' className="illustration-tight-box" src="https://pandahut.net/wp-content/uploads/2021/06/ph-discord-integration.png" alt="Discord API Integration" />
                            <p className="text">• PH integrates its services by developing custom plugins and APIs, providing greater functionality to our players.<br /><br />
                            • If you'll be away from the game for a while, our Discord bot can alert you if your in-game objects take damage.</p>
                        </div>
                        <a className="features-btn" href="https://pandahut.net/discord/">JOIN OUR DISCORD</a>
                    {/* 
                    // Discord Member count = {discordInfo.Members}  [Doesnt work]   
                    <div className="about-us-col-item col-display">
                                <h5 className="features-title">A Sneak Peak Into It!</h5>
                                <iframe src="https://discord.com/widget?id=272081718020669440&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
                            </div> 
                        <p>To access the Pandahut Discord channel, <a href="https://phn.pw/discord">Click Here</a>  */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
}
