module.exports = {
    RankPerks: [{
            Name: "Reserved Slot",
            Type: "bool",
            HiddenText: "Access to 2 Reserved Slots Normal Players don't get, usually 94/96.",
            isSpecialElement: false,
            ServerTypeAssociated: [],
            HeaderRowClass: "",
        },
        {
            Name: "Chat Tag Color",
            Type: "html",
            HiddenText: "Valid on all PH servers.\nYour rank color and your rank tag in-game chat are colored accordingly.\nMVP++ members can select from multiple colors and disguise their tag as any other rank (VIP to MVP+).",
            isSpecialElement: true,
            ServerTypeAssociated: [],
            HeaderRowClass: "",
        },
        {
            Name: "Weapon Kits",
            Type: "html",
            HiddenText: "Can be used once every 2 hours for all Ranks.\nYou can use it by doing /kit WeaponName on our PvP and OP Kits servers. All weapon kits come with ammo.",
            isSpecialElement: true,
            ServerTypeAssociated: ["PvP", "KitPvP"],
            HeaderRowClass: "select-box-header",
        },
        {
            Name: "PvP / KitPvP Bamboo",
            Type: "html",
            HiddenText: "Payment Lump Sum (Bamboo) awarded after claiming the rank.",
            isSpecialElement: false,
            ServerTypeAssociated: ["PvP", "KitPvP"],
            HeaderRowClass: "",
        },
        {
            Name: "RP Bamboo",
            Type: "html",
            HiddenText: "Payment Lump Sum (Bamboo) awarded after claiming the rank.",
            isSpecialElement: false,
            ServerTypeAssociated: ["RP"],
            HeaderRowClass: "",
        },
        {
            Name: "Hourly Salary",
            Type: "html",
            HiddenText: "Hourly Salary (Bamboo) you receive for playing on PvP, KitPvP, or RP",
            isSpecialElement: false,
            ServerTypeAssociated: ["PvP", "RP", "KitPvP"],
            HeaderRowClass: "",
        },
        {
            Name: "PvP / KitPvP Kits",
            Type: "html",
            HiddenText: "Kits available to ranked members.",
            isSpecialElement: false,
            ServerTypeAssociated: ["PvP", "KitPvP"],
            HeaderRowClass: "",
        },
        {
            Name: "PvP / KitPvP Extra Perks",
            Type: "html",
            HiddenText: "Secure Virtual Storage Containers. \nOn PvP & KitPvP, you can use them anywhere on the map.",
            isSpecialElement: false,
            ServerTypeAssociated: ["PvP", "KitPvP"],
            HeaderRowClass: "double-row",
        },
        {
            Name: "RP Extra Perks",
            Type: "html",
            HiddenText: "VAULTS:\n- Vaults are secure virtual Storage Containers to which you have exclusive access. On RP, you can only use them in preset locations (usually banks).\n\nPLAYER SIGN SHOPS:\n- Sign Shops allow players to trade with others even when the shop owner is offline. \n- Unranked players may place up to 10 sign shops. \n- VIP players may place up to 20 sign shops. \n- VIP+ players may place up to 30 sign shops. \n- MVP players may place up to 40 sign shops. \n- MVP+ players may place up to 50 sign shops.",
            isSpecialElement: false,
            ServerTypeAssociated: ["RP"],
            HeaderRowClass: "triple-row",
        },
        {
            Name: "Unturnov Extra Perks",
            Type: "html",
                HiddenText: "VAULTS:\n- Secure, Cross-Server Virtual Storage Containers. On Unturnov, you can only use Vaults near the elevators in the bunker.\n - Kappa Vault: On Unturnov 1.25 Servers, the Kappa vault is an additional vault (Gamma + Kappa) that can be used anywhere on the map. It consists of double the number of slots as the Gamma vault (12 vs 6). \n\nRANKED APARTMENTS:\n- On Unturnov 1.25 Servers, simply use the main bunker's elevator and get exclusive access to Ranked Apartments.\n- Ultimate Apartments are regular-sized, exclusive to VIP and VIP+ Members.\n- Supreme Apartments are larger and luxurious, exclusive to MVP, MVP+ and MVP++ Members only.\n- Supply Limited, available upon every map wipe on a first come, first-serve basis.\n\nPLAYER SIGN SHOPS:\n- Sign Shops allow players to trade with others even when the shop owner is offline.",
            isSpecialElement: false,
            ServerTypeAssociated: ["Unturnov"],
            HeaderRowClass: "triple-row-sm",
        },
        {
            Name: "PvP Airdrops",
            Type: "html",
            HiddenText: "Ability to call an Airdrop to a random location on the map.",
            isSpecialElement: false,
            ServerTypeAssociated: ["PvP", "KitPvP"],
            HeaderRowClass: "",
        },
        {
            Name: "RP Airdrops",
            Type: "html",
            HiddenText: "Ability to call an Airdrop to a random location on the map.",
            isSpecialElement: false,
            ServerTypeAssociated: ["RP"],
            HeaderRowClass: "",
        },
        {
            Name: "Slower Decay",
            Type: "bool",
            HiddenText: "GEN BURNRATE\n- On servers with *Generator-Based Decay*, VIP to VIP+ members' generators burn fuel at 25% slower rate.\n- On servers with *Generator-Based Decay*, MVP, MVP+, and MVP++ members' generators burn fuel 50% slower rate.\n- This applies even when you are logged out.\n\n- On servers with *Login-Based Decay*, VIP & VIP+ members have +1 extra day before decay starts.\n- On servers with *Login-Based Decay*, MVP & MVP+ members have +2 extra days before decay starts.\n- This applies even when you are logged out.",
            isSpecialElement: false,
            ServerTypeAssociated: [],
            HeaderRowClass: "",
        },
        {
            Name: "Auto Pickup",
            Type: "bool",
            HiddenText: "AUTO PICKUP\n- Auto Pickup lets harvested resources automagically go into your inventory instead of manually picking them up.\n- The commands `/autopickup tree`, `/autopickup stick`, `/autopickup resource` will toggle these auto-pickup modes on and off.\n- `autoPickup tree`: automatically pick up nearby logs.\n- `autopickup stick` automatically pick up nearby sticks.\n- `autopickup resource` automatically pick up other resources nearby.\n- You must be within a radius of two meters of the resource being picked up.",
            isSpecialElement: false,
            ServerTypeAssociated: ["PvP", "RP", "KitPvP", "Rust", "Vanilla+", "Semi-Vanilla"],
            HeaderRowClass: "",
        },
        {
            Name: "Custom Discord Alerts",
            Type: "bool",
            HiddenText: "DISCORD ALERTS\n- Discord Alerts notify you in Discord when your base is raided or decaying.\n- Use the !notifications Discord Command in the Pandahut Discord to set which servers you wish to enable or disable Discord Alerts.\n- For example: You can configure Discord Alerts to ignore all servers except PH Server #2.",
            isSpecialElement: false,
            ServerTypeAssociated: [],
            HeaderRowClass: "",
        },
        {
            Name: "Exclusive RP Roles",
            Type: "html",
            HiddenText: "Roleplay Server Roles exclusive to each rank or lower.",
            isSpecialElement: true,
            ServerTypeAssociated: ["RP"],
            HeaderRowClass: "double-row-sm",
        },
        {
            Name: "RP Garage Slots",
            Type: "html",
            HiddenText: "Virtual Garage Slots.",
            isSpecialElement: true,
            ServerTypeAssociated: ["RP"],
            HeaderRowClass: "",
        },
        {
            Name: "KitPvP Garage Slots",
            Type: "html",
            HiddenText: "Virtual Garage Slots.",
            isSpecialElement: true,
            ServerTypeAssociated: ["KitPvP"],
            HeaderRowClass: "",
        },
        {
            Name: "Buy & Sell Ammo & Attachments on RP",
            Type: "bool",
            HiddenText: "Reardless of your role, have access to ammo and attachment from the server shop. No weaponsmith is on? No problem!",
            isSpecialElement: false,
            ServerTypeAssociated: ["RP"],
            HeaderRowClass: "",
        },
        {
            Name: "RP Server Role Kits",
            Type: "html",
            HiddenText: "Roleplay Server Kits exclusive to each rank or lower.\nSome are upgraded versions of the roles kits (i.e vipbandit vs normal bandit).\nSome are completely new kits you get access to (i.e getting Police Sniper and Police Rifle as police).",
            isSpecialElement: true,
            ServerTypeAssociated: ["RP"],
            HeaderRowClass: "",
        },
        
        {
            Name: "Custom Kits Limit",
            Type: "html",
            HiddenText: "Limit of Custom Kits / Loadouts you can have on KitPvP or Creative.\nDefault is 4. ",
            isSpecialElement: true,
            ServerTypeAssociated: ["Creative", "KitPvP"],
            HeaderRowClass: "",
        },
    ],
    Ranks: [{
            RankName: "VIP",
            Price: "$5.00 USD",
            Color: "#B0FE0E",
            UpgradeOptions: [],
            ColorName: "Green",
            Icon: "https://pandahut.net/wp-content/uploads/2021/06/vipicon_h8.png",
            PermWeapons: 1,
            RankPerks: [{
                    Name: "Reserved Slot",
                    Value: false,
                    RowClass: ""
                },
                {
                    Name: "Chat Tag Color",
                    Value: null,
                    RowClass: ""
                },
                {
                    Name: "Weapon Kits",
                    Value: ["Bluntforce", "Cobra", "Colt", "Desert Eagle", "Determinator", "Devil's Bane", "Luger", "Maple Rifle", "Master Key", "MP40", "Pine Rifle"],
                    RowClass: ""
                },
                {
                    Name: "PvP / KitPvP Bamboo",
                    Value: "<div>$80,000 Bamboo</div>",
                    RowClass: ""
                },
                {
                    Name: "RP Bamboo",
                    Value: "<div>$30,000 Bamboo</div>",
                    RowClass: ""
                },
                {
                    Name: "Hourly Salary",
                    Value: "<div>$250 Bamboo/Hour</div>",
                    RowClass: ""
                },
                {
                    Name: "PvP / KitPvP Kits",
                    Value: "<div><a target='_blank' href='https://help.pandahut.net/63d059e1de4855eb4c066e12/What-Kits-Do-Ranked-Members-Receive-on-PvP-&-KitPvP-Servers?'>Click to View</div>",
                    RowClass: ""
                },
                {
                    Name: "PvP / KitPvP Extra Perks",
                    Value: "<div>24-Slot Storage Vault (6×4)</div>",
                    RowClass: "double-row"
                },
                {
                    Name: "RP Extra Perks",
                    Value: "<div>24-Slot Storage Vault (6×4)<br /> Access to Tier 1 Sign Shops</div>",
                    RowClass: "triple-row"
                },
                {
                    Name: "Unturnov Extra Perks",
                    Value: "<div>Stash Vault (15 x 20)<br /> Access to Ultimate Apartments<br /> Place Up To 15 Sign Shops</div>",
                    RowClass: "triple-row-sm"
                },
                {
                    Name: "PvP Airdrops",
                    Value: "Cooldown: 90 Minutes",
                    RowClass: ""
                },
                {
                    Name: "RP Airdrops",
                    Value: "Cooldown: 4 Hours",
                    RowClass: ""
                },
                {
                    Name: "Slower Decay",
                    Value: true,
                    RowClass: ""
                },
                {
                    Name: "Auto Pickup",
                    Value: false,
                    RowClass: ""
                },
                {
                    Name: "Custom Discord Alerts",
                    Value: false,
                    RowClass: ""
                },
                {
                    Name: "Exclusive RP Roles",
                    Value: ["Druglord", "Uber Driver", "Terrorist"],
                    RowClass: ""
                },
                {
                    Name: "RP Garage Slots",
                    Value: "5 Slots",
                    RowClass: ""
                },
                {
                    Name: "KitPvP Garage Slots",
                    Value: "3 Slots",
                    RowClass: ""
                },
                {
                    Name: "Buy & Sell Ammo & Attachments on RP",
                    Value: true,
                    RowClass: ""
                },
                {
                    Name: "RP Server Role Kits",
                    Value: {
                        "Police Sniper Kit": ["Normal Cop Loadout", "Timberwolf", "Timberwolf Mag x3"],
                        "Police Rifle Kit": ["Normal Cop Loadout", "Eaglefire", "Military Magazines x3"],
                        "VIP Police Kit": ["Full Cop Gear", "Military Crates x2", "Empire, Handcuff, Handcuff Key"],
                        "VIP Bandit Kit": ["Bandit Clothing", "Bluntforce", "Vertical Grip", "Military Magazine", "Military Drum, Cable Ties"],
                        "VIP Doctor Kit": ["Doctor Clothing", "Alicepack", "Medkit x2", "Vaccine x2", "Antibiotics x2"],
                        "VIP Builder Kit": ["Builder Clothing", "Pine Log x10", "Alicepack", "Chainsaw", "Metal Scrap x15"],
                        "VIP Chef Kit": ["Chef Clothing", "Alicepack+ Bacon", "Cooked Venison x2", "Grilled Cheese Sandwich x2"],
                        "VIP Farmer Kit": ["Farmer Clothing", "Alicepack", "Wheat Seed x2", "Lettuce Seed x2", "Carrot Seed x2", "Corn Seed x2"]
                    },
                    RowClass: ""
                },
                {
                    Name: "Custom Kits Limit",
                    Value: "6 Slots",
                    RowClass: ""
                },
            ],
        },
        {
            RankName: "VIP+",
            UpgradeOptions: [{ "Name": "Upgrade: VIP to VIP+", "PriorRank": "VIP", "Price": 5 }],
            Price: "$10.00 USD",
            Color: "#FFFF00",
            ColorName: "Yellow",
            Icon: "https://pandahut.net/wp-content/uploads/2021/06/vipplusicon_h7-2.png",
            PermWeapons: 1,
            RankPerks: [{
                    Name: "Reserved Slot",
                    Value: false,
                    RowClass: ""
                },
                {
                    Name: "Chat Tag Color",
                    Value: null,
                    RowClass: ""
                },
                {
                    Name: "Weapon Kits",
                    Value: ["Ace", "Avenger", "BirchRifle", "Bluntforce", "Cobra", "Colt", "DesertEagle", "Determinator", "DevilsBane", "Empire", "Fusilaut", "Hawkhound", "Kryzkarek", "Luger", "MapleRifle", "Maplestrike", "Masterkey", "MP40", "Nykorev", "PineRifle", "Schofield", "Sportshot", "Teklowvka", "Timberwolf", "Zubeknakov"],
                    RowClass: ""
                },
                {
                    Name: "PvP / KitPvP Bamboo",
                    Value: "<div>$180,000 Bamboo</div>",
                    RowClass: ""
                },
                {
                    Name: "RP Bamboo",
                    Value: "<div>$100,000 Bamboo</div>",
                    RowClass: ""
                },
                {
                    Name: "Hourly Salary",
                    Value: "<div>$500 Bamboo/Hour</div>",
                    RowClass: ""
                },
                {
                    Name: "PvP / KitPvP Kits",
                    Value: "<div><a target='_blank' href='https://help.pandahut.net/63d059e1de4855eb4c066e12/What-Kits-Do-Ranked-Members-Receive-on-PvP-&-KitPvP-Servers?'>Click to View</div>",
                    RowClass: ""
                },
                {
                    Name: "PvP / KitPvP Extra Perks",
                    Value: "<div>36-Slot Storage Vault (6×6)</div>",
                    RowClass: "double-row"
                },
                {
                    Name: "RP Extra Perks",
                    Value: "<div>36-Slot Storage Vault (6×6)<br /> Access to Tier 1 Sign Shops</div>",
                    RowClass: "triple-row"
                },
                {
                    Name: "Unturnov Extra Perks",
                    Value: "<div>Stash Vault (15 x 20)<br /> Access to Ultimate Apartments<br /> Place Up To 20 Sign Shops<br /> Access to the Quick-Sell NPC</div>",
                    RowClass: "triple-row-sm"
                },
                {
                    Name: "PvP Airdrops",
                    Value: "Cooldown: 90 Minutes",
                    RowClass: ""
                },
                {
                    Name: "RP Airdrops",
                    Value: "Cooldown: 4 Hours",
                    RowClass: ""
                },
                {
                    Name: "Slower Decay",
                    Value: true,
                    RowClass: ""
                },
                {
                    Name: "Auto Pickup",
                    Value: true,
                    RowClass: ""
                },
                {
                    Name: "Custom Discord Alerts",
                    Value: false,
                    RowClass: ""
                },
                {
                    Name: "Exclusive RP Roles",
                    Value: ["Druglord", "Uber Driver", "Kidnapper", "Body Guard", "Terrorist"],
                    RowClass: ""
                },
                {
                    Name: "RP Garage Slots",
                    Value: "10 Slots",
                    RowClass: ""
                },
                {
                    Name: "KitPvP Garage Slots",
                    Value: "5 Slots",
                    RowClass: ""
                },
                {
                    Name: "Buy & Sell Ammo & Attachments on RP",
                    Value: true,
                    RowClass: ""
                },
                {
                    Name: "RP Server Role Kits",
                    Value: {
                        "Police Sniper Kit": ["Normal Cop Loadout", "Timberwolf", "Timberwolf Mag x3"],
                        "Police Rifle Kit": ["Normal Cop Loadout", "Eaglefire", "Military Magazines x3"],
                        "VIP+ Police Kit": ["Full Cop Gear", "Military Crates x5", "Empire, Handcuff x2, Handcuff Key x2"],
                        "VIP+ Bandit Kit": ["Bandit Clothing", "Zubeknakov", "Tactical Laser", "Red Dot Sight", "Vertical Grip", "Ranger Magazine x3", "Ranger Drum x2, Cable Ties"],
                        "VIP+ Doctor Kit": ["Doctor Clothing", "Alicepack", "Medkit x4", "Vaccine x4", "Antibiotics x3", "Suturekit x3"],
                        "VIP+ Builder Kit": ["Builder Clothing", "Pine Log x20", "Alicepack", "Chainsaw", "Metal Scrap x25"],
                        "VIP+ Chef Kit": ["Chef Clothing", "Alicepack", "Bacon x3", "Cooked Vension x2", "Grilled Cheese Sandwich x3", "Cake x2"],
                        "VIP+ Farmer Kit": ["Farmer Clothing", "Alicepack", "Wheat Seed x4", "Lettuce Seed x4", "Carrot Seed x4", "Corn Seed x4", "Planter x4"]
                    },
                    RowClass: ""
                },
                {
                    Name: "Custom Kits Limit",
                    Value: "6 Slots",
                    RowClass: ""
                },
            ],
        },
        {
            RankName: "MVP",
            Price: "$20.00 USD",
            UpgradeOptions: [{ "Name": "Upgrade: VIP to MVP", "PriorRank": "VIP", "Price": 15.00 }, { "Name": "Upgrade: VIP+ to MVP", "PriorRank": "VIP+", "Price": 10.00 }],
            Color: "#64ACFB",
            ColorName: "Blue",
            Icon: "https://pandahut.net/wp-content/uploads/2021/06/mvpicon_h6-1.png",
            PermWeapons: 1,
            RankPerks: [{
                    Name: "Reserved Slot",
                    Value: false,
                    RowClass: ""
                },
                {
                    Name: "Chat Tag Color",
                    Value: null,
                    RowClass: ""
                },
                {
                    Name: "Weapon Kits",
                    Value: ["Ace", "Augewehr", "Avenger", "BirchRifle", "Bluntforce", "Bulldog", "Cobra", "Colt", "DesertEagle", "Determinator", "DevilsBane", "Dragonfang", "Eaglefire", "Empire", "Fusilaut", "Hawkhound", "Heartbreaker", "Kryzkarek", "Luger", "MP40", "MapleRifle", "Maplestrike", "Masterkey", "Matamorez", "Nykorev", "Peacemaker", "PineRifle", "Sabertooth", "Scalar", "Schofield", "Snayperskya", "Sportshot", "Teklowvka", "Timberwolf", "Zubeknakov"],
                    RowClass: ""
                },
                {
                    Name: "PvP / KitPvP Bamboo",
                    Value: "<div>$400,000 Bamboo</div>",
                    RowClass: ""
                },
                {
                    Name: "RP Bamboo",
                    Value: "<div>$250,000 Bamboo</div>",
                    RowClass: ""
                },
                {
                    Name: "Hourly Salary",
                    Value: "<div>$750 Bamboo/Hour</div>",
                    RowClass: ""
                },
                {
                    Name: "PvP / KitPvP Kits",
                    Value: "<div><a target='_blank' href='https://help.pandahut.net/63d059e1de4855eb4c066e12/What-Kits-Do-Ranked-Members-Receive-on-PvP-&-KitPvP-Servers?'>Click to View</div>",
                    RowClass: ""
                },
                {
                    Name: "PvP / KitPvP Extra Perks",
                    Value: "<div><b>Two</b> 36-Slot Storage Vaults (6×6)</div>",
                    RowClass: "double-row"
                },
                {
                    Name: "RP Extra Perks",
                    Value: "<div><b>Two</b> 36-Slot Storage Vaults (6×6)<br /> Access to Tier 2 Sign Shops</div>",
                    RowClass: "triple-row"
                },
                {
                    Name: "Unturnov Extra Perks",
                    Value: "<div>Kappa Vault (3 x 4)<br /> Stash Vault (15 x 20)<br /> Access to Supreme Apartments<br /> Place Up To 30 Sign Shops<br /> Access to the Quick-Sell NPC</div>",
                    RowClass: "triple-row-sm"
                },
                {
                    Name: "PvP Airdrops",
                    Value: "Cooldown: 60 Minutes",
                    RowClass: ""
                },
                {
                    Name: "RP Airdrops",
                    Value: "Cooldown: 2 Hours",
                    RowClass: ""
                },
                {
                    Name: "Slower Decay",
                    Value: true,
                    RowClass: ""
                },
                {
                    Name: "Auto Pickup",
                    Value: true,
                    RowClass: ""
                },
                {
                    Name: "Custom Discord Alerts",
                    Value: false,
                    RowClass: ""
                },
                {
                    Name: "Exclusive RP Roles",
                    Value: ["Druglord", "Uber Driver", "Kidnapper", "Body Guard", "CEO", "Salesman", "Terrorist"],
                    RowClass: ""
                },
                {
                    Name: "RP Garage Slots",
                    Value: "15 Slots",
                    RowClass: ""
                },
                {
                    Name: "KitPvP Garage Slots",
                    Value: "10 Slots",
                    RowClass: ""
                },
                {
                    Name: "Buy & Sell Ammo & Attachments on RP",
                    Value: true,
                    RowClass: ""
                },
                {
                    Name: "RP Server Role Kits",
                    Value: {
                        "Police Sniper Kit": ["Normal Cop Loadout", "Timberwolf", "Timberwolf Mag x3"],
                        "Police Rifle Kit": ["Normal Cop Loadout", "Eaglefire", "Military Magazines x3"],
                        "MVP Police Kit": ["Full Cop Gear", "Military Crates x7", "Empire, Handcuff x3, Handcuff Key x3"],
                        "MVP Bandit Kit": ["Bandit Clothing", "Zubeknakov", "Tactical laser x2", "Red Dot Sight x2", "Vertical grip x2", "Ranger Magazine x4", "Ranger Suppressor x2", "Ranger Drum x3", "Yuri", "Yuri Magazine, Cable Ties"],
                        "MVP Doctor Kit": ["Doctor Clothing", "Alicepack", "Medkit x5", "Vaccine x5", "Antibiotics x4", "Suturekit x3"],
                        "MVP Builder Kit": ["Builder Clothing", "Pine Log x20", "Alicepack", "Chainsaw", "Metal Scrap x25"],
                        "MVP Chef Kit": ["Chef Clothing", "Alicepack", "Bacon x3", "Cooked Vension x2", "Grilled Cheese Sandwich x3", "Cake x2"],
                        "MVP Farmer Kit": ["Farmer Clothing", "Alicepack", "Wheat Seed x6", "Lettuce Seed x6", "Carrot Seed x6", "Corn Seed x6", "Planter x6"]
                    },
                    RowClass: ""
                },
                {
                    Name: "Custom Kits Limit",
                    Value: "6 Slots",
                    RowClass: ""
                },
            ],
        },
        {
            RankName: "MVP+",
            Price: "$40.00 USD",
            UpgradeOptions: [{ "Name": "Upgrade: VIP to MVP+", "PriorRank": "VIP", "Price": 35.00 }, { "Name": "Upgrade: VIP+ to MVP+", "PriorRank": "VIP+", "Price": 30 }, { "Name": "Upgrade: MVP to MVP+", "PriorRank": "MVP", "Price": 20 }],
            Color: "#F52F2F",
            ColorName: "Red",
            Icon: "https://pandahut.net/wp-content/uploads/2021/06/mvpplusicon_h5.png",
            PermWeapons: 2,
            RankPerks: [{
                    Name: "Reserved Slot",
                    Value: true,
                    RowClass: ""
                },
                {
                    Name: "Chat Tag Color",
                    Value: null,
                    RowClass: ""
                },
                {
                    Name: "Weapon Kits",
                    Value: ["Ace", "Augewehr", "Avenger", "BirchRifle", "Bluntforce", "Bulldog", "Card", "Cobra", "Colt", "Crossbow", "DesertEagle", "Determinator", "DevilsBane", "Dragonfang", "Eaglefire", "Ekho", "Empire", "Fusilaut", "Grizzly", "Hawkhound", "Heartbreaker", "Honeybadger", "Kryzkarek", "Luger", "MapleRifle", "Maplestrike", "Masterkey", "Matamorez", "MP40", "Nightraider", "Nykorev", "Peacemaker", "PineRifle", "Sabertooth", "Scalar", "Schofield", "Shadowstalker", "Snayperskya", "Sportshot", "Teklowvka", "Timberwolf", "Vonya", "Zubeknakov"],
                    RowClass: ""
                },
                {
                    Name: "PvP / KitPvP Bamboo",
                    Value: "<div>$880,000 Bamboo</div>",
                    RowClass: ""
                },
                {
                    Name: "RP Bamboo",
                    Value: "<div>$550,000 Bamboo</div>",
                    RowClass: ""
                },
                {
                    Name: "Hourly Salary",
                    Value: "<div>$1000 Bamboo/Hour</div>",
                    RowClass: ""
                },
                {
                    Name: "PvP / KitPvP Kits",
                    Value: "<div><a target='_blank' href='https://help.pandahut.net/63d059e1de4855eb4c066e12/What-Kits-Do-Ranked-Members-Receive-on-PvP-&-KitPvP-Servers?'>Click to View</div>",
                    RowClass: ""
                },
                {
                    Name: "PvP / KitPvP Extra Perks",
                    Value: "<div><b>Two</b> 48-Slot Storage Vaults (8×6)</div>",
                    RowClass: "double-row"
                },
                {
                    Name: "RP Extra Perks",
                    Value: "<div><b>Two</b> 48-Slot Storage Vaults (8×6)<br /> Access to Tier 2 Sign Shops</div>",
                    RowClass: "triple-row"
                },
                {
                    Name: "Unturnov Extra Perks",
                    Value: "<div>Kappa Vault (3 x 4)<br /> Stash Vault (15 x 20)<br /> Stash+ Vault (20 x 20)<br /> Access to Supreme Apartments<br /> Place Up To 40 Sign Shops<br /> Access to the Quick-Sell NPC</div>",
                    RowClass: "triple-row-sm"
                },
                {
                    Name: "PvP Airdrops",
                    Value: "Cooldown: 60 Minutes",
                    RowClass: ""
                },
                {
                    Name: "RP Airdrops",
                    Value: "Cooldown: 2 Hours",
                    RowClass: ""
                },
                {
                    Name: "Slower Decay",
                    Value: true,
                    RowClass: ""
                },
                {
                    Name: "Auto Pickup",
                    Value: true,
                    RowClass: ""
                },
                {
                    Name: "Custom Discord Alerts",
                    Value: true,
                    RowClass: ""
                },
                {
                    Name: "Exclusive RP Roles",
                    Value: ["Druglord", "Uber Driver", "Kidnapper", "Body Guard", "CEO", "Salesman", "SWAT", "Hitman", "Terrorist"],
                    RowClass: "",
                },
                {
                    Name: "RP Garage Slots",
                    Value: "20 Slots",
                    RowClass: ""
                },
                {
                    Name: "KitPvP Garage Slots",
                    Value: "20 Slots",
                    RowClass: ""
                },
                {
                    Name: "Buy & Sell Ammo & Attachments on RP",
                    Value: true,
                    RowClass: ""
                },
                {
                    Name: "RP Server Role Kits",
                    Value: {
                        "Police Sniper Kit": ["Normal Cop Loadout", "Timberwolf", "Timberwolf Mag x3"],
                        "Police Rifle Kit": ["Normal Cop Loadout", "Eaglefire", "Military Magazines x3"],
                        "MVP+ Police Kit": ["Full Cop Gear", "Military Crates x7 +Empire ", "Handcuff x4", "Handcuff Key x4"],
                        "MVP+ Bandit Kit": ["Bandit Clothing", "Zubeknakov", "Tactical Laser x2", "Red Dot Sight x2", "Vertical Grip x2", "Ranger Magazine x4", "Ranger Suppressor x2", "Ranger Drum x3", "Yuri", "Yuri Magazine", "Cable Ties"],
                        "MVP+ Doctor Kit": ["Doctor Clothing", "Alicepack", "Medkit x5", "Vaccine x5", "Antibiotics x4", "Suturekit x3"],
                        "MVP+ Builder Kit": ["Builder Clothing", "Pine Log x20", "Alicepack", "Chainsaw", "Metal Scrap x25"],
                        "MVP+ Chef Kit": ["Chef Clothing", "Alicepack", "Bacon x3", "Cooked Vension x2", "Grilled Cheese Sandwich x3", "Cake x2"],
                        "MVP+ Farmer Kit": ["Farmer Clothing", "Alicepack", "Wheat Seed x6", "Lettuce Seed x6", "Carrot Seed x6", "Corn Seed x6", "Planter x6"]
                    },
                    RowClass: ""
                },
                {
                    Name: "Custom Kits Limit",
                    Value: "10 Slots",
                    RowClass: ""
                },
            ],
        },
        
    ],
};
