import React from 'react';
import { useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-bootstrap/Carousel';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default function HeroSectionTop() {
    const topRef = useRef();

    const scrollUp = () => {
        if (document.querySelector(".back-to-top").classList.contains("fadeIn"))
            document.querySelector(".back-to-top").classList.remove("fadeIn");

        if (!document.querySelector(".back-to-top").classList.contains("fadeOut"))    
            document.querySelector(".back-to-top").classList.add("fadeOut");

        const element = document.body;
        element.scrollIntoView({behavior: "smooth", block: "start"})
    };

    return <section ref={topRef} id="home" className="slider-area">
    <Carousel interval={null} activeIndex={1} controls={false} touch={false} id="Home" className="carousel slide">
    <Carousel.Caption>
     
    </Carousel.Caption>
    <Carousel.Item>
    <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="slider-content">
                                <h1 className="title">The Better Way To Start The Game</h1>
                                <p className="text">A virtual home to thousands of gamers worldwide, Pandahut hosts enterprise-grade dedicated servers for the PC game <a style="color: #ffffff; font-size: inherit;" href="https://store.steampowered.com/app/304930/Unturned/">Unturned</a>.</p>
                                <div className="buttons">
                                    <a href="https://pandahut.net/servers" className="btn btn-primary custom-button red-btn">Our Servers</a>
                                    <a href="https://pandahut.net/forums" className="btn btn-primary custom-button blue-btn">Forums</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-image-box d-none d-lg-flex align-items-end">
                        <div className="slider-image">
                            <img loading='lazy' src="https://pandahut.net/wp-content/uploads/2021/06/hero-section-server-sm-alpha-0.png" alt="PH Infrastrucure" />
                        </div>
                    </div>
                </div>
  </Carousel.Item>
</Carousel>

<a href="#Home" onClick={scrollUp} className="back-to-top"><FontAwesomeIcon icon={faChevronUp} /></a>
</section>
}
